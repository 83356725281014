.collection-component-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 15vh;
}

.footer-container {
  margin-top: 5vh;
  margin-bottom: 5vh;
  display: flex;
  justify-content: center;
}