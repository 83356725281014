.welcome-container {
    display: none;
    margin-top: 15vh;
}

@media (min-width: 768px) {
    .welcome-container {
        display: block;
    }
}

.welcome-row {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    width: 100%;
}

.welcome-logo-col {
    display: none;
}

@media (min-width: 768px) {
    .welcome-logo-col {
        display: block;
    }
}

.welcome-img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottoni-container-welcome-desktop {
    padding: 2%;
    border: 5px solid #252525;
    letter-spacing: 0.2rem;
    font-size: 2vh;
    word-break: break-all;
    display: flex;
    justify-content: center;
}

p.bottoni-container-welcome-desktop:hover {
    background-color: #252525;
    color: white;
}