.custom-link-style {
  position: absolute;
  margin-bottom: 6vh;
  z-index: 7;
  border: 1;
  text-decoration: none;
  background-color: transparent;
}

.img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden
}

.titolo-container-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  font-size: 2.4rem;
}

.bottoni-container-welcome-mobile {
  padding: 2%;
  border: 3px solid black;
  letter-spacing: 0.2rem;
  font-size: 1rem;
  word-break: break-all;
  display: flex;
  justify-content: center
}

.container-bottoni-welcome-mobile {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: space-around;
  margin-top: 2vh;
}