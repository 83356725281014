.div-responsive-tile {
    height: 100%;
    marginLeft: 0.5%;
    marginRight: 0.5%;
    marginBottom: 0.5%
}

.div-img {
    width: 100%;
    height: 100%;
    objectFit: contain
}