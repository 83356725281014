body.modal-open {
  overflow: auto !important;
}

body.modal-open[style] {
  padding-right: 0px !important;
}

.modal-backdrop {
  display: none;
}

.navbar-container {
  height: 15vh;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  display: grid;
}