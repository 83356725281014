a.social {
    transition: transform 250ms;
    display: inline-block;
    margin: 20px;
  }
  
  a.social:hover {
    transform: translateY(-5px);
  }
    
  a.facebook {
    color: #4968ad;
}


  
  


  
  

