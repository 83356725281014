.icons a {
    /* background: white; */

    position: relative;
    height: 7vh; /* Utilizzo di vh per l'altezza */
    width: 7vh; /* Utilizzo di vh per la larghezza */
    margin: 0 2vw; /* Utilizzo di vw per il margin */
    display: inline-flex;
    text-decoration: none;
    /* border-radius: 50%; */
    transition: all 0.3s;
    box-shadow: -0.3vw -0.3vw 0.7vw #ffffff, /* Utilizzo di vw per il box-shadow */
                0.3vw 0.3vw 0.5vw #ceced1; /* Utilizzo di vw per il box-shadow */
                
}

.icons a:hover:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* border-radius: 50%; */
    background: #ecf0f3;
    box-shadow: inset -0.3vw -0.3vw 0.7vw #ffffff, /* Utilizzo di vw per il box-shadow */
                inset 0.3vw 0.3vw 0.5vw #ceced1; /* Utilizzo di vw per il box-shadow */
}
    


  .icons a i{

    position: relative;
    z-index: 3;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 25px;
    line-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    

  }
  .icons a:hover i{
    transform: scale(0.9);
  }
  .icons a.fb i{
    color: #4267B2;
  }

  .icons a.insta i{
    color: #E1306C;
  }
    
