.product {
    margin-top: 0.5vh;
    display: flex;
    justify-content: center;
    width: 100%;
    background: linear-gradient(to top, #060709, #47515b);
    height: 75vh;
    align-items: flex-end;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product-logo {
    width: 35vh;
}

.product-img {
    width: 70vh;
}