a {
  color: #000 !important;
  text-decoration: none !important;
}

.contatti-modal .modal-content {
  backdrop-filter: none !important;
  display: flex;
  align-items: center;
  height: 60vh;
  width: 100%;
  justify-content: space-between;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.125);
}

div.modal-dialog.modal-lg.modal-dialog-centered {
  justify-content: center;
  margin-top: 5vh;
}

div {
  font-family: "Anton", sans-serif
}

.hover {
  --c: linear-gradient(#000 0 0);
  padding-bottom: .15em;
  background: var(--c), var(--c);
  background-size: .3em .1em;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  transition: .3s linear, background-size .3s .2s linear;
}


.hover:hover {
  background-size: 40% .1em;
  background-position: 10% 100%, 90% 100%;
}

.modal-content {
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(15px) !important;
}

.modal-body {
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.modal-body {

  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
}

[class] {
  vertical-align: middle;
  cursor: pointer;
}

@media (max-width: 768px) {
  div {
    font-size: 0.9em;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
}