.swiper-wrapper {
    margin-top: 0.5vh;
}
.swiper-slide.swiper-slide-active {
    width: 50%!important 
}

.swiper-slide.swiper-slide-prev {
    width: 25%!important 
}

.swiper-slide.swiper-slide-next {
    width: 25%!important;
}

.swiper-slide {
    width: 25%!important;
    height: auto;
}