.container-gradient-nav {
    overflow: hidden;
    width: 100%;
    height: 90vh;
    position: relative;
}

#gradient {
    width: 100%;
    height: 100%;
}

.prodotto {
    position: absolute;
    top: 16%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
    flex-direction: column;
}

.logo-img {
    width: 40vh;
    margin-top: 10vh;
}

.product-img {
    width: 70vh;
}

.button-section {
    display: flex;
    gap: 1vh;
}


